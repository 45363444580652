var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex",class:[
        _vm.inline ? 'flex-row align-center': 'flex-col',
    ]},[(_vm.label)?_c('label',{staticClass:"mb-1 pr-4 text-uppercase font-bold block",class:[
      _vm.requiredLabel ? 'required' : '',
      _vm.italic ? 'text-italic' : '',
      _vm.opacityClass,
      _vm.labelClass
      ],attrs:{"for":"input"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-col basis"},[_c('md-field',[_c('md-textarea',{staticClass:"border border-solid rounded border-gray-400 input-box-shadow arrow_hidden",class:[_vm.inputClass],staticStyle:{"box-shadow":"0px 0px 4px 1px #bcc2d4cf"},attrs:{"disabled":_vm.disabled,"readonly":_vm.readOnly,"md-autogrow":""},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1),(_vm.message)?_c('b',{staticClass:"text-danger",attrs:{"id":"scrollTop"}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }