import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user/";
import job from "./modules/job/";
import organization from "./modules/organization/";
import dummyOrganization from "./modules/dummyOrganization/";
import packages from "./modules/package/";
import applicant from "./modules/applicant/";
import question from "./modules/question";
import testCreation from "./modules/testCreation";
import examination from "./modules/examination";
import explanation from "./modules/explanation";
import sampleAnswer from "./modules/sampleAnswer";
import studyGuide from "./modules/studyGuide";
import prompt from "./modules/prompt";
import registration from "./modules/registration";
import cms from "./modules/cms";
import material from "./modules/material";
import result from "./modules/result";
import test from "./modules/test"


Vue.use(Vuex)
const store = new Vuex.Store({
  strict: true,
  modules: {
    cms,
    user,
    question,
    testCreation,
    examination,
    explanation,
    sampleAnswer,
    studyGuide,
    prompt,
    registration,
    job,
    applicant,
    material,
    organization,
    dummyOrganization,
    packages,
    result,
    test
  },
  state: {
    showNavigation: false,
    showDialog: false,
    showSnackbar: false,
    sidebar: 'default',
    snackbarMessage: null,
    count: 10,
  },
  mutations: {
    toggleDrawer: (state, payload) => state.showNavigation = payload,
    setSidebar: (state, payload) => state.sidebar = payload,
    setShowDialog: (state, payload) => state.showDialog = payload,
    setShowSnackbar: (state, payload) => {
      state.showSnackbar = true,
      state.snackbarMessage = payload
    },
    setCloseSnackbar: state => state.showSnackbar = false,
    SET_COUNT: (state, payload) => state.count = payload
  }
})

export default store;