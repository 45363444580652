<template>

  <md-button :type="type" :class="loading? 'opacity-75': ''" :disabled="loading" @click="$emit('click')">
  <md-icon v-if="loading" class="loader text-sm text-white">cached</md-icon> {{ !loading ? label: 'Loading ...' }}
  <md-icon v-if="checked" class="text-sm text-white">&check;</md-icon>
  </md-button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'button'
    }
  },
};
</script>

<style scoped>
.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

</style>