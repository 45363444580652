var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex",class:[
        _vm.inline ? 'flex-row align-center': 'flex-col',
    ]},[(_vm.label)?_c('label',{staticClass:"mb-1 pr-4 text-uppercase font-bold block",class:[
      _vm.requiredLabel ? 'required' : '',
      _vm.italic ? 'text-italic' : '',
      _vm.opacityClass,
      _vm.labelClass
      ],attrs:{"for":"input"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-col basis"},[_c('input',{staticClass:"border border-solid rounded border-gray-400 input-box-shadow",class:[_vm.inputClass],attrs:{"id":"pte-input_number","type":_vm.type,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readOnly},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.$emit('HandleTargetValue')},"input":function($event){return _vm.$emit('input', $event.target.value)}}}),(_vm.message)?_c('b',{staticClass:"text-danger",attrs:{"id":"scrollTop"}},[_vm._v(_vm._s(_vm.message))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }