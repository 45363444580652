<template>
  <form @submit.prevent="$emit('submit')" class="search-box-area relative outline-gray-400">
    <div class="flex rounded">
      <input class="search-box" :placeholder="placeholder" :value="value" type="text" @input="$emit('input', $event.target.value)" />
      <md-icon class="m-0 pb-1 search-icon" @click.native="$emit('submit')">search</md-icon>
    </div>
    <div v-show="results.length > 0" class="search-result-area">
      <md-divider></md-divider>
      <ul>
        <li
          v-for="(result, index) in results"
          :key="index"
          @click="$emit('on-result', result[resultKey])"
        >
          {{ result[resultKey] }}
        </li>
      </ul>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String
    },
    results: {
      default: () => []
    },
    resultKey: {
      type: String
    },
    value: {
      type: String
    }
  }
};
</script>

<style lang="scss">
  .search-box-area {
    border-radius: 4px;
  }
  .search-result-area {
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    top: 38px;
    background: #fff;
    z-index: 222;
    border: 1px solid #cbd5e0;
    border-top-color: transparent;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 250px;
    overflow-y: auto;
    li {
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background-color: rgb(221, 220, 220);
      }
    }
  }
  .search-box {
    border: none;
    width: 100%;
    outline: none;
    background-color: inherit;
  }
  .search-icon {
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    &:hover {
      background-color: rgba(235, 235, 235, 0.603)
    }
  }
</style>
