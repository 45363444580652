export const student = [
  {
    path: '/student',
    name: 'student',
    component: {
      render(c) { return c('router-view') }
    },
    redirect: '/student/portal',
    children: [
      {
        path: 'portal',
        name: 'student.portal',
        component: () => import(/* webpackChunkName: "student-portal" */ '../views/student/StudentPortalView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'portfolio-and-history',
        component: {
          render: c => c('router-view')
        },
        meta: { layout: 'student' },
        children: [
          {
            path: '',
            name: 'student.portfolio',
            component: () => import(/* webpackChunkName: "student-portfolio-and-history" */ '../views/student/profile/StudentPortfolioAndHistoryView.vue'),
            meta: { layout: 'student' },
          },
          
          {
            path: 'data-list',
            name: 'student.portfolio.dataList',
            component: () => import(/* webpackChunkName: "student-portfolio-and-history" */ '../views/student/profile/DataListView.vue'),
            meta: { layout: 'student' },
          },

        ]
      },
      {
        path: 'schedule',
        name: 'student.schedule',
        component: () => import(/* webpackChunkName: "student-schedule" */ '../views/student/StudentScheduleView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'study-plan',
        name: 'student.studyPlan',
        component: () => import(/* webpackChunkName: "student-study-plan" */ '../views/student/StudentStudyPlanView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'practice-test',
        component: {
          render: c => c('router-view')
        },
        children: [
          {
            path: '',
            name: 'student.practiceTest.resultview',
            component: () => import(/* webpackChunkName: "student-practice-test" */ '../views/student/result/ResultView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: '',
            name: 'student.practiceTest',
            component: () => import(/* webpackChunkName: "student-practice-test" */ '../views/student/practice-test/IndexView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: 'question-participation/:id',
            name: 'student.practiceTestQuestionParticipation',
            component: () => import(/* webpackChunkName: "student-practice-test-question-participation" */ '../views/common/QuestionParticipationView.vue'),
            meta: { layout: 'student' }
          },
        ]
      },
      {
        path: 'mock-exam',
        component: {
          render: c => c('router-view')
        },
        children: [
          {
            path: '',
            name: 'student.mockExam',
            component: () => import(/* webpackChunkName: "student-mock-exam" */ '../views/student/mock-test/MockExamCollapseView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: ':slug/mock-test/:id',
            name: 'student.mockExamPreview',
            component: () => import(/* webpackChunkName: "student-mock-exam" */ '../views/student/mock-test/MockExamPreviewView.vue'),
            meta: { layout: 'student' }
          },
        ]
      },
      {
        path: 'pte-practice',
        name: 'student.ptePractice',
        component: {
          render: c => c('router-view')
        },
        meta: { layout: 'student' },
        redirect: { name: 'student.ptePractice.nav' },
        children: [
          {
            path: '',
            name: 'student.ptePractice.nav',
            component: () => import(/* webpackChunkName: "student-pte-practice" */ '../views/student/pte-practice/PtePracticeCollapseView.vue'),
            //component: () => import(/* webpackChunkName: "student-pte-practice" */ '../views/student/pte-practice/PtePracticeListView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: 'collapse',
            name: 'student.ptePractice.collapse',
            component: () => import(/* webpackChunkName: "student-pte-practice-reading" */ '../views/student/pte-practice/PtePracticeCollapseView.vue'),
            meta: { layout: 'student' },
          },
          {
            path: 'test',
            name: 'student.ptePractice.question',
            component: () => import(/* webpackChunkName: "student-pte-practice-writing" */ '../views/common/QuestionParticipationView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: ':slug/pte/:pteId',
            component: {
              render: c => c('router-view')
            },
            children: [
              {
                path: '',
                name: 'student.ptePractice.questionList',
                component: () => import(/* webpackChunkName: "student-pte-practice-reading" */ '../views/student/pte-practice/QuestionListView.vue'),
                meta: { layout: 'student' },
              },
             
            ]
          },
          {
            path: ':slug/pte-preview/:id',
            name: 'student.ptePracticePreview',
            component: () => import( '../views/student/pte-practice/ptePracticeSlide.vue'),
            meta: { layout: 'student' }
          },
        ]
      },
      {
        path: 'quiz-test',
        component: {
          render: c => c('router-view')
        },
        children: [
          {
            path: '',
            name: 'student.quizTest',
            component: () => import(/* webpackChunkName: "student-quiz-test" */ '../views/student/quiz-test/IndexView.vue'),
            meta: { layout: 'student' },
          },
          {
            path: ':id',
            name: 'student.quizTest.Question',
            component: () => import(/* webpackChunkName: "student-quiz-test" */ '../views/common/QuestionParticipationView.vue'),
            meta: { layout: 'student' },
          },

        ]
      },
      {
        path: 'practice-material',
        name: 'student.practiceMaterial',
        component: () => import(/* webpackChunkName: "student-material" */ '../views/student/practice-material/IndexView.vue'),
        meta: { layout: 'student' },
        redirect: { name: 'student.material.list' },
        children: [
          {
            path: 'list',
            name: 'student.material.list',
            component: () => import(/* webpackChunkName: "student-material-list" */ '../views/student/practice-material/StudentMaterialListView.vue'),
            meta: { layout: 'student' }
          },

          {
            path: 'grid',
            name: 'student.material.grid',
            component: () => import(/* webpackChunkName: "student-material-grid" */ '../views/student/practice-material/StudentMaterialGridView.vue'),
            meta: { layout: 'student' }
          },

          {
            path: 'directory',
            name: 'student.material.directory',
            component: () => import(/* webpackChunkName: "student-material-directory" */ '../views/student/practice-material/StudentMaterialDirectoryView.vue'),
            meta: { layout: 'student' }
          },
        ]
      },
      {
        path: 'result',
        name: 'student.result',
        component: () => import(/* webpackChunkName: "student-result" */ '../views/student/result/ResultListView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: '/student/result/evaluation',
        name: 'student.evaluation',
        component: () => import( '../views/student/evaluation/indexView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: '/student/result/words-api',
        name: 'student.evaluation',
        component: () => import( '../views/student/evaluation/dic/test.vue'),
        meta: { layout: 'student' }
      },
      {
        path: '/student/result/oxford',
        name: 'student.evaluation',
        component: () => import( '../views/student/evaluation/dic/Oxford.vue'),
        meta: { layout: 'student' }
      },
      {
        path: '/student/result/free-api',
        name: 'student.evaluation',
        component: () => import( '../views/student/evaluation/dic/freeApi.vue'),
        meta: { layout: 'student' }
      },
      {
        path: '/evaluation',
        name: 'student.evaluation',
        component: () => import( '../views/student/evaluation/indexView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'live-class',
        name: 'student.liveClass',
        component: () => import(/* webpackChunkName: "student-live-class" */ '../views/common/live-class/IndexView.vue'),
        meta: { layout: 'student' },
        redirect: { name: 'student.liveClass.nowPlaying' },
        children: [
          {
            path: 'now-playing',
            name: 'student.liveClass.nowPlaying',
            component: () => import(/* webpackChunkName: "student-live-class-now-paying" */ '../views/student/live-class/NowPlayingView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: 'next-live',
            name: 'student.liveClass.nextLive',
            component: () => import(/* webpackChunkName: "student-live-class-next-live" */ '../views/common/live-class/NextLiveView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: 'schedule',
            name: 'student.liveClass.schedule',
            component: () => import(/* webpackChunkName: "student-live-class-schedule-view" */ '../views/common/live-class/ScheduleView.vue'),
            meta: { layout: 'student' }
          },
          {
            path: 'schedule/list',
            name: 'student.liveClass.scheduleList',
            component: () => import(/* webpackChunkName: "student-live-class-schedule-list" */ '../views/common/live-class/ScheduleListView.vue'),
            meta: { layout: 'student' }
          },
        ]
      },
      {
        path: 'communication',
        name: 'student.communication',
        component: () => import(/* webpackChunkName: "student-communication" */ '../views/student/StudentCommunicationView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'online-class',
        name: 'student.onlineClass',
        component: () => import(/* webpackChunkName: "student-online-class" */ '../views/student/StudentOnlineClassView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'how-to-use-this-portal',
        name: 'studentHowToUseThisPortal',
        component: () => import(/* webpackChunkName: "student-how-to-use" */ '../views/common/HowToUseThisPortalView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'faq',
        name: 'studentFaq',
        component: () => import(/* webpackChunkName: "student-faq" */ '../views/common/FAQView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'blog',
        name: 'studentBlog',
        component: () => import(/* webpackChunkName: "student-blog" */ '../views/common/BlogView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'material',
        name: 'studentMaterial',
        component: () => import(/* webpackChunkName: "student-page-material" */ '../views/common/MaterialView.vue'),
        meta: { layout: 'student' }
      },
      {
        path: 'registration-process',
        name: 'studentRegistrationProcess',
        component: () => import(/* webpackChunkName: "student-registration-process" */ '../views/common/RegistrationProcessView.vue'),
        meta: { layout: 'student' }
      }
    ]
  }
]