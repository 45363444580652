import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'


import i18n from './i18n';

// UI component
import VueMaterial from 'vue-material';
Vue.use(VueMaterial);

// CK Editor Install
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

// Audio Wavefurfer
import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

// Audio Wavefurfer
import VueWaveSurfer from 'vue-wave-surfer'
Vue.use(VueWaveSurfer)

// Audio recorder
import AudioRecorder from 'vue-audio-recorder'
Vue.use(AudioRecorder)

// Video player
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
  plyr: {}
})
// Form validation with vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Stripe Plugin
import { StripePlugin } from '@vue-stripe/vue-stripe';
const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  // stripeAccount: "ca_JdhMiKAE2OMkfoLCBunVpUTb5ZLwzFfC",
  // stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_STRIPE_API_VERSION,
  locale: process.env.VUE_APP_STRIPE_LOCALE,
};


Vue.use(StripePlugin, options);

// Time picker
import 'vue2-timepicker/dist/VueTimepicker.css'

// localStorage mixin
import localStorage from './mixins/localStorage' 
// Styling assets
import './assets/sass/main.scss';
// Global components
import TeacherLayout from './components/layouts/TeacherLayout.vue';
import StudentLayout from './components/layouts/StudentLayout.vue';
import AdminLayout from './components/layouts/AdminLayout.vue';
import MasterLayout from './components/layouts/MasterLayout.vue';
import LoginLayout from './components/layouts/LoginLayout';
import RegistrationLayout from './components/layouts/RegistrationLayout';
import CoachingCenterLayout from './components/layouts/CoachingCenterLayout';
import JobApplicationLayout from './components/layouts/JobApplicationLayout';
import NotFoundLayout from './components/layouts/NotFoundLayout';
Vue.component('teacher-layout', TeacherLayout);
Vue.component('student-layout', StudentLayout);
Vue.component('admin-layout', AdminLayout);
Vue.component('master-layout', MasterLayout);
Vue.component('login-layout', LoginLayout);
Vue.component('registration-layout', RegistrationLayout);
Vue.component('coaching-center-layout', CoachingCenterLayout);
Vue.component('job-application-layout', JobApplicationLayout);
Vue.component('not-found-layout', NotFoundLayout);

Vue.config.productionTip = false

Vue.mixin(localStorage);
new Vue({
  router,
  store,
  i18n,
  data () {
    return {
      prograss : false,
      CurrentTime: '',
      //loading: false
    }},
  render: h => h(App)
}).$mount('#app')
