<template>
  <div class="p-6 p-xs-4">
    <div class="ap-main-layout">
      <div class="ap-sidebar hidden-sm hidden-xs p-2" :style="(sidebar == 'default') ? 'min-width:350px; transition: .2s' : 'min-width:180px; transition: .2s'">
        <img class="mb-6" src="/images/logo.png" alt="" />
        <component :is="SidbarLayout" :items="menus" :avatar="avatar"></component>
      </div>
      <div class="ap-main p-2">
        <TopMenu :items="items" />
        <div class="mt-6 bg-white p-6 rounded-lg" style="margin-top: 100px !important;">
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "@/components/layouts/TopMenu";
import SidbarLayout from "@/components/layouts/SidbarLayout";
import MiniSidbarLayout from "@/components/layouts/MiniSidebarLayout";
import menus from '@/data/sidebar/student/items'
import items from '@/data/top-navigation/student-top-navigation'
import { mapState } from "vuex";
export default {
  components: {
    TopMenu,
    SidbarLayout,
    MiniSidbarLayout
  },
  data: () => ({
    menus: menus,
    SidbarLayout: 'SidbarLayout',
    items:items,
    avatar: {
      role: 'Student ID: 1524189'
    }
  }),
  computed: {
    ...mapState({
      sidebar: (state) => state.sidebar,
    })
  }
};
</script>
