<template>
  <div>
    <label v-if="label" :class="titleClass" class="pr-4 text-right text-uppercase font-bold">
      {{ label }}
    </label>
    <div :class="{'flex': !block}">
      <div v-for="(item, index) in checkboxItems" :key="index">
        <md-checkbox
          class="flex"
          v-model="checkbox"
          :value="item.id"
          :class="[labelClass, item.active]"
        >
          <span
            class="rounded p-2 py-1 pr-6"
            :class="{ 'outline-gray-400': labelOutline }"
            >{{ item.label }}</span
          >
        </md-checkbox>
      </div>
    </div>

    <div id="scrollTop" class="text-xs text-danger" v-if="message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => null,
    },
    message: {
      type: String,
      default: () => null,
    },
    labelClass: {
      type: String,
      default: () => null,
    },
    titleClass: {
      type: String,
      default: () => null,
    },
    labelOutline: {
      type: Boolean,
      default: () => true,
    },
    block: {
      type: Boolean,
      default: () => true,
    },
    value: {
      type: [String, Array]
    },
    items: {
      type: Array,
      default: () => [
        { id: 1, label: "Answer 01", active: "" },
        { id: 2, label: "Answer 02", active: "" },
      ],
    },
  },
  data: () => ({
    checkbox: [],
  }),
  computed: {
    checkboxItems() {
      return this.items;
    },
  },
  watch: {
    checkbox(value) {
      this.$emit("input", value);
    },
  },
};
</script>
