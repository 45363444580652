import api from '../api';
import { includes, reject } from 'lodash';
const state = () => ({
    
    cartQuestions: [],
    practiceTestList: [],
    sectionWiseTests: [],
    sectionQuestionTypes:[],
    PTEPracticeQuestion:[],
    questions: []
});


const getters = {
    
    getCartQuestions: state => state.cartQuestions,
    getPracticeTestList: state => {
        return state.practiceTestList.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    getSectionWiseTests: state => state.sectionWiseTests.map(item => ({
              id: item.id,
              name: item.title
            }
    )),
    getSectionQuestionTypes: state => state.sectionQuestionTypes.map(item => ({
        id: item.id,
        name: item.title
    }
    )),
    getPTEPracticeQuestion: state => state.PTEPracticeQuestion.map(item => ({
        id: item.id,
        name: item.title,
        t_id: item.t_id,
        created_at: item.created_at.split(' '),
    }
    )),
    getPTEPracticeQuestionDetails: state => state.PTEPracticeQuestion.map(item => ({
        id: item.id,
        questions: item.questions
    }
    ))
}

const mutations = {
    setCartQuestions(state, question) {
        if(question && !includes(state.cartQuestions, question)) { // need to update with object
            try {
                const index = state.cartQuestions.findIndex(cq => cq.id === question.id);
                if(index > -1) {
                    return;
                }
            } catch(e) {
                console.log(e);
            }
            state.cartQuestions.push(question);
        }
    },
    setCartClear: state => state.cartQuestions = [],
    setRemoveCartQuestion: (state, question) => {
        state.cartQuestions = reject(state.cartQuestions, question)
    },
    setPracticeTestList: (state, payload) => state.practiceTestList = payload,
    setSectionWiseTests: (state, payload) => state.sectionWiseTests = payload,
    setSectionQuestionTypes:(state, payload) => state.sectionQuestionTypes = payload,
    setPTEPracticeQuestion:(state, payload) => state.PTEPracticeQuestion = payload,
}

const actions = {
    async actTestCreationSave ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/tests', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actTestStepUpdate ( store , {testId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/tests/${testId}`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    
    async actTestPreview ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/tests/questions`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },


    
    async actPracticeTestList ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/list${query}`)
                    .then( ({ items }) => {
                        commit('setPracticeTestList',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actSectionWiseTests ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests${query}`)
                    .then( ({ items }) => {
                        commit('setSectionWiseTests', items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actSectionQuestionTypes ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/question-types${query}`)
                    .then( ({ items }) => {
                        commit('setSectionQuestionTypes', items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actPTEPracticeQuestion ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/pte-practice-list${query}`)
                    .then( ({ items }) => {
                        commit('setPTEPracticeQuestion', items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actTestSections ()  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/section-wise-count`)
                    .then( ({ items }) => {
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    // async actTestSections ()  {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api('get', `api/v1/tests/section-wise-count`)
    //                 .then( ({ items }) => {
    //                     resolve(items)
    //                 })
    //                 .catch(error => reject(error))
    //             } catch (error) {
    //                 reject(error);
    //             }
    //     })
    // },
    async actTestDetails (store, testId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/${testId}`)
                    .then( ({ items }) => {
                        resolve(items)
                        
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actTestQuestionsIds (store, testId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/review/questions/${testId}`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actTestStatusUpdate (store, {testId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/tests/change-status/${testId}`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }