import api from '../api';
import { find, filter } from 'lodash';
import cms from '@/data/cms'
// initial state
const state = () => ({
    questionTypes: [],
    quiz: [],
    selectedQuestionType: {},
    typeWiseQuestion: [],
    sectionWiseQuestion: [],
    quizWiseQuestion: [],
    materials: [],
    typeWiseQuestions: [],
    singleQuestion: {},
    questionHeader: {},
    PTEPracticeQuestionDetails: []
});

// getters
const getters = {
    getQuestionTypes: state => {
        return state.questionTypes.map(item => {
            return {
                id: item.id,
                name: item.title,
                slug: item.slug
            }
        });
    },
    getQuiz: state => {
       
        return state.quiz.map(item => {
            return {
                id: item.id,
                name: item.type,
            }
        });
    },
    getSectionWiseQuestion: state => {

        return state.sectionWiseQuestion.map((item, index) => {
             item.sl = index + 1;
             return item;
         })
     },
     getQuizWiseQuestion: state => {

        return state.quizWiseQuestion.map((item, index) => {
             item.sl = index + 1;
             return item;
         })
     },

     getMaterials: state => {

        return state.materials.map((item, index) => {
             item.sl = index + 1;
             return item;
         })
     },

    getTypeWiseQuestions: state => state.typeWiseQuestions,
    getSelectedQuestionType: state => state.selectedQuestionType,
    getSelectedQuiz: state => state.SelectedQuiz,
    getSingleQuestion: state => state.singleQuestion,
    PTEPracticeQuestionDetails: state => state.PTEPracticeQuestionDetails,
    //PTEPracticeQuestionDetails:(state, payload) => state.PTEPracticeQuestionDetails = payload,
}
// mutations
const mutations = {
    setQuestionTypes(state, questionTypes) {
        state.questionTypes = questionTypes
    },
    setQuiz(state, setQuiz) {
        state.quiz = setQuiz
    },
    setSelectedQuiz(state, type) { 
        let { component } = find(cms.questionTypes, ['slug',  type.slug]);
        console.log("Component"+component);
        type.component = component;
        state.selectedQuiz = type;
    },
    setSelectedQuestionType(state, type) {
        let { component } = find(cms.questionTypes, ['slug',  type.slug]);
        console.log("Component"+component);
        type.component = component;
        state.selectedQuestionType = type;
    },

    setSectionWiseQuestion(state, questions) {
        state.sectionWiseQuestion = questions;
    },
    setQuizWiseQuestion(state, questions) {
        state.quizWiseQuestion = questions;
    },
    setMaterials(state, questions) {
        state.materials = questions;
    },
    setTypeWiseQuestions (state, questions) {
        state.typeWiseQuestions = questions;
    },
    setRemoveDeletedItem(state, questionId) {
        state.sectionWiseQuestion = filter(state.sectionWiseQuestion, (item) => item.id !== questionId)
    },
    setPTEPracticeQuestionDetails(state, question) {
        state.PTEPracticeQuestionDetails = question;
    },
    setQuestionDetails(state, question) {
        state.singleQuestion = question;
    },
    setSelectedQuestion(state, payload) {
       let question =  find(state.sectionWiseQuestion, ['id', payload.id])
       question.isSelected = true;
    }
}

const actions = {
    /*
        Section wise questions [section means 4 main section or module {Reading, Writing, Speaking, Listening}]
    */
    async actGetQuestionTypes ({ commit } , query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/question-types${query}`)
                    .then( ({ items }) => {
                        commit('setQuestionTypes', items);
                        commit('setSelectedQuestionType', items[0]);
                        resolve(items[0]);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actGetQuiz ({ commit } , query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-type${query}`)
                    .then( ({items}) => {
                        
                        commit('setQuiz', items);
                        commit('setSelectedQuiz', items[0]);
                        resolve(items[0]);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    
    async actQuizWiseQuestion ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz${query}`)
                    .then(( {items} ) => {
                        commit('setQuizWiseQuestion',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /*
        quiz save
    */
    async actSubmitQuiz ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/quiz', payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actMaterials ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/materials${query}`)
                    .then(( {items} ) => {
                        commit('setMaterials',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    /*
        Question type details by type id
    */
    async actQuestionTypeDetails (store , typeId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/question-types/${typeId}`)
                    .then( ({ items }) => {
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
       /*
        Quiz type details by type id
    */
        async actQuizTypeDetails (store , typeId)  {
            return new Promise((resolve, reject) => {
                try {
                    api('get', `api/v1/quiz-type/${typeId}`)
                        .then( ({ items }) => {
                            resolve(items);
                        })
                        .catch(error => reject(error))
                    } catch (error) {
                        reject(error);
                    }
            })
        },
    /*
        questions save
    */
    async actSubmitQuestion ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/questions', payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Question listing api, query string for filtering
    */
    async actSectionWiseQuestion ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/questions/list${query}`)
                    .then(({ items }) => {
                        commit('setSectionWiseQuestion',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Type wise questions [type means 20 type of question]
    */
    async actTypeWiseQuestions ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/questions${query}`)
                    .then(({ items }) => {
                        commit('setTypeWiseQuestions',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    /*
        Question delete
    */
    async actDeleteQuestion ({ commit } , questionId)  {
        return new Promise((resolve, reject) => {
            try {
                api('delete', `api/v1/questions/${questionId}`)
                    .then(({ message }) => {
                        commit('setRemoveDeletedItem', questionId)
                        resolve(message);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Question update
    */
    async actUpdateQuestion (store, {questionId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/questions/${questionId}`, payload)
                    .then(({ message }) => {
                        resolve(message);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Question details
    */
    async actQuestionDetails ({ commit } , questionId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/questions/${questionId}`)
                    .then(({ items }) => {
                        commit('setQuestionDetails', items);
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
   
    /*
        PTE practice Question all
    */
    async actPTEPracticeQuestionDetails ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1//tests/pte-practice-list${query}`)
                    .then(({ items }) => {
                        commit('setPTEPracticeQuestionDetails', items);
                        resolve(items);
                        console.log("hello",items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }