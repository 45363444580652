import Vue from 'vue'
import VueRouter from 'vue-router'
import { teacher } from './teacher'
import { student } from './student'
import { admin } from './admin'
import { master } from './master'
import { common } from './common'
import { coachingCenter } from './coachingCenter'
import { course } from './course'
import { packages } from './packages'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes : [
    ...teacher, ...student,
    ...admin, ...master,
    ...common,
    ...coachingCenter,
    ...course,
    ...packages



  ],
  
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

let excepts = ['login', 'registration'];


router.beforeEach((to, from, next) => {

  
  var firstSegment = window.location.pathname.split('/')[1];
  var userType = localStorage.getItem('user_type')
  if(userType){
    if(userType.toLowerCase() !== firstSegment && (firstSegment == 'master' || firstSegment == 'admin' || firstSegment == 'student' || firstSegment == 'teacher' )){
      window.location.replace('/'+userType.toLowerCase()+'/portal');
    }
  }
   
  let defaultLayout = to.meta.layout || 'login';

  if (!excepts.includes(defaultLayout)) {
    let token = localStorage.getItem('token');
    if (!token) {
       next('/login')
    }
  }

  next();
});

export default router;
