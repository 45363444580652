<template>
  <div
    class="flex"
    :class="[
        inline ? 'flex-row align-center': 'flex-col',
    ]"
  >
    <label
      v-if="label"
      for="input"
      class="mb-1 pr-4 text-uppercase font-bold block"
      :class="[
      requiredLabel ? 'required' : '',
      italic ? 'text-italic' : '',
      opacityClass,
      labelClass
      ]">
      {{ label }}
    </label>
    <div class="flex flex-col basis">
      <input
        id="pte-input_number"
        :type="type"
        class="border border-solid rounded border-gray-400 input-box-shadow"
        :class="[inputClass]"
        :maxlength="maxlength" 
        :placeholder="placeholder"
        :value="value"
        @blur="$emit('HandleTargetValue')"
        @input="$emit('input', $event.target.value)"
        :disabled="disabled"
        :readonly="readOnly"
      />
      <b class="text-danger" id="scrollTop" v-if="message">{{ message }}</b>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    italic: {
      type: Boolean,
    },
    opacityClass: {
      type: String,
    },
    labelClass: {
      type: String
    },
    inputClass: {
      type: String,
      default: () => 'p-3'
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    inline: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    value: {
      type: [String, Array, Number]
    },
    type: {
      type: String,
      default: () => 'text'
    },
    readOnly: {
      type: Boolean,
      default: () => false
    },
    condition: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    maxlength: {
      type: String,
    }
  },
  mounted(){
   //console.log("da",this.$route.query.type,this.condition);
    if(this.$route.query.type == "pte-practice" ){
      if(this.condition == false){
        //this.maxlength = 1;      
      }
    }
  },
  methods:{
        
  },
};
</script>
<style>

</style>