var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-flex",class:[
        _vm.inline ? 'flex-row align-center': 'flex-col',
  ]},[(_vm.label)?_c('label',{staticClass:"pr-4 inline-block mb-1 text-uppercase font-bold",class:[
        _vm.requiredLabel ? 'required' : '',
        _vm.italic ? 'text-italic' : '',
      ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-col basis relative"},[_c('select',{staticClass:"select-component input-box-shadow py-3 pl-3 pr-8 pointer",class:[
          _vm.borderNone ? 'border-none': 'outline-gray-400'
        ],attrs:{"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.onChangeSelect}},_vm._l((_vm.items),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('md-icon',{staticClass:"arrow-icon"},[_vm._v("arrow_drop_down")]),(_vm.message)?_c('b',{staticClass:"text-danger",attrs:{"id":"scrollTop"}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }